export const settings = {
  GIST_RENDERER_ENDPOINT: 'https://renderer.gist.build/2.0',
  GIST_API_ENDPOINT: 'https://api.gist.build',
  ENGINE_API_ENDPOINT: 'https://engine.api.gist.build',
  CAPTCHA_CLIENT_KEY: '6LfbYK8ZAAAAAAJNav_GfxmvHkiECBZsJWYTW3Mw',
  GIST_WEB_ENV: 'prod',
  GIST_WEB_ORGANIZATION_ID: 'dad61f4f-24ad-4281-828b-6d3b215aad28',
  CUSTOMER_IO_SITE_ID: '3e0ca917d0ca4f67f87f',
  MIXPANEL_TOKEN: '87393569c4d60fdfbd9cecb8271f0f34',
  SENTRY_DSN:
    'https://53049f126a35c78b6695a0edbef9f73d@o38230.ingest.sentry.io/4506747219148800',
};
